import React from 'react'
import Faq from '../../components/Faq'

const KnowledgeCenter = () => {
  return (
    <>
      <section class="section" style={{minHeight:"100vh"}}>
      <Faq/>

      </section>
    </>
  )
}

export default KnowledgeCenter
